
import ReactDOM from 'react-dom/client';

const getIndexFile = () => {

  interface Environments {
    [index: string]: string;
    LOGIN : string,
    APP : string
  }
  const environments : Environments = {
    LOGIN: 'index-login',
    APP: 'index-app',
  }
  
  const buildTarget = process.env.REACT_APP_BUILD_TARGET;
  
  if (buildTarget) {
    const result : string = environments[buildTarget]
    
    // A check to avoid typo
    if (!result) {
      throw new Error(`Incorrect REACT_APP_BUILD_TARGET: ${buildTarget}`)
    }
    return result
  }

}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
import(`./index/${getIndexFile()}`).then(({render})=>{
  root.render(
    render()
  );
})